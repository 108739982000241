import React from "react";
import styled from "styled-components";
import { CONSTANTS } from "../constants";
import LazyImage from "./LazyImage";
import { SectionHeading, Wrapper } from "./shared";

export default function Gallery({ photos }) {
  return (
    <Wrapper>
      <SectionHeading>Gallery</SectionHeading>
      <p>Have a browse of some of the work I've done recently.</p>
      <PhotoGrid>
        {photos.map((photo) => (
          <LazyImage
            key={photo.number}
            src={photo.image[0].url}
            alt={photo.caption || ""}
          ></LazyImage>
        ))}
      </PhotoGrid>
    </Wrapper>
  );
}

const PhotoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-row-gap: ${CONSTANTS.space[6]};
  grid-column-gap: ${CONSTANTS.space[5]};
  align-items: stretch;
`;
