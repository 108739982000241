import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Gallery from "../components/Gallery";
import config from "../../data/SiteConfig";

export default function GalleryPage({ data }) {
  const photos = data.allAirtable.nodes.map((node) => node.data);
  return (
    <Layout>
      <Helmet title={`Gallery | ${config.siteTitle}`} />
      <Gallery photos={photos} />
    </Layout>
  );
}

export const pageQuery = graphql`
  query GalleryQuery {
    allAirtable(sort: { fields: data___number }) {
      nodes {
        data {
          caption
          number
          image {
            url
          }
        }
      }
    }
  }
`;
