import React from "react";
import styled, { keyframes } from "styled-components";
import LazyLoad from "react-lazyload";

export default function LazyImage({ src, alt }) {
  return (
    <LazyLoad height={400} offset={1500} placeholder={<Placeholder />} once>
      <StyledImage src={src} alt={alt} />
    </LazyLoad>
  );
}

const loadingAnimation = keyframes`
  0% {
    background-color: #fff;
  }

  50% {
    background-color: #ccc;
  }

  100% {
    background-color: #fff;
  }
`;

const Placeholder = styled.div`
  width: 100%;
  height: 400px;
  animation: ${loadingAnimation} 1s infinite;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 400px;
  object-fit: cover;
`;
